/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = {
  appointmentsData: {
    allAppointments: [],
    currentPeriodData: [],
    lastPeriodData: [],
    heatMapResult: [],
  },
  generalInformation: {
    currentPeriod: {
      currentKPIs: {},
      currentProductivity: {}
    },
    lastPeriod: {
      lastProductivity: {},
      prevKPIs: {}
    }
  },
};

const businessesDashboard = createSlice({
  name: 'businessesDashboard',
  initialState,
  reducers: {
    completeLoadBusinessDashboardData: (state, { payload }) => {
      return {
        ...state,
        ...(payload && payload),
      };
    },
    setDefault: () => {
      return initialState;
    }
  },
});

export const {
  completeLoadBusinessDashboardData,
  setDefault
} = businessesDashboard.actions;

export default businessesDashboard.reducer;

export const loadBusinessesDashboardData = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/businesses-dashboard/calculate-data`, config);
  if (!data?.isCheckWork && response && !response?.checkStatus) {
    return dispatch(completeLoadBusinessDashboardData(response));
  } else {
    return {}
  }
};

export const setDefaultBusinessesDashboard = () => (dispatch) => {
  return dispatch(setDefault())
};