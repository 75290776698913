import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const invoices = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    completeLoadSortableInvoices: (state, { payload }) => {
      const ids = payload.invoices.map(({ id }) => id);
      const byId = payload.invoices.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadInvoice: (state, { payload }) => {
      let ids = [...state.ids];
      if (ids.indexOf(payload.id) === -1) {
        ids = [...ids, payload.id];
      }

      return {
        ...state,
        ids,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeCreateInvoiceWithItems: (state, { payload }) => {
      return {
        ...state,
        byId: {
          [payload.id]: payload,
          ...state.byId,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateInvoiceWithItems: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteInvoice: (state, { payload }) => {
      const { [payload]: deletedInvoice, ...byId } = state.byId;

      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadSortableInvoices,
  completeLoadInvoice,
  completeCreateInvoiceWithItems,
  completeUpdateInvoiceWithItems,
  completeDeleteInvoice,
} = invoices.actions;

export default invoices.reducer;

export const loadSortableInvoicesByClient = (options) => {
  return async (dispatch, getState) => {
    const { auth: { token } } = getState();
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    const response = await http.get(`${window.baseApiPath}/invoices/get-by-client`, config);

    return dispatch(completeLoadSortableInvoices(response));
  };
};

export const loadInvoiceWithItemsAndPayments = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(
    `${window.baseApiPath}/invoices/${id}/get-with-items-and-payments`,
    config,
  );

  return dispatch(completeLoadInvoice(response));
};

export const createInvoiceWithAppointmentItem = (options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/invoices/create-with-appointment-item`, config);

  return dispatch(completeCreateInvoiceWithItems(response));
};

export const createInvoiceWithItemsAndClient = (data, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data: {...data, siteUrl: window.location.origin},
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/invoices/create-with-items-and-client`, config);

  return dispatch(completeCreateInvoiceWithItems(response));
};

export const updateInvoiceWithItems = (id, data, options) => async (dispatch, getState) => {
  const siteUrl = window.location.origin;
  const { auth: { token }} = getState();
  const config = {
    data: {...data, siteUrl},
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/invoices/${id}/update-with-items`, config);

  return dispatch(completeUpdateInvoiceWithItems(response));
};

export const deleteInvoice = (id, options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/invoices/${id}`, config);

  return dispatch(completeDeleteInvoice(id));
};
