import { createSlice } from '@reduxjs/toolkit';

import http from '../shared_client_utils/http';
import { makeOption } from '../shared_client_utils/formUtils';
import { LocationsApi } from '../client_http_api';

const initialState = { ids: [], byId: {} };

const locations = createSlice({
  name: 'locations',
  initialState: initialState,
  reducers: {
    completeLoadLocations: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: {
          ...currValue,
          ...makeOption(currValue),
        },
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedLocations: (state, { payload }) => {
      const ids = payload.locations.map(({ id }) => id);
      const byId = payload.locations.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: {
          ...currValue,
          ...makeOption(currValue),
        },
      }), {});

      return { ...state, byId, ids };
    },
    completeCreateLocation: (state, { payload }) => {
      const ids = [...state.ids, payload.id ];
      const byId = {
        ...state.byId,
        [payload.id]: {
          ...payload,
          ...makeOption(payload),
        },
      };

      return { ...state, byId, ids };
    },
    completeUpdateLocation: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...payload,
            ...makeOption(payload),
          },
        },
      };
    },
    completeDeleteLocation: (state, { payload }) => {
      const { [payload]: deletedLocation, ...byId } = state.byId;

      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadLocations,
  completeLoadSortedLocations,
  completeCreateLocation,
  completeUpdateLocation,
  completeDeleteLocation,
} = locations.actions;

export default locations.reducer;

export const loadLocations = () => async (dispatch, getState) => {
  const { auth } = getState()
  const response = await LocationsApi.fetchLocations(auth);

  return dispatch(completeLoadLocations(response));
};

export const loadSortedLocations = (options) => async (dispatch, getState) => {
  const { auth: { token }} = getState()
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/locations/get-sorted`, config);

  return dispatch(completeLoadSortedLocations(response));
};

export const loadWorkingLocationsForStaff = (staffId) => async (dispatch, getState) => {
  const { auth: { token }} = getState()
  const config = {
    params: { staffId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/locations/get-working-locations-for-staff`, config);

  return dispatch(completeLoadLocations(response));
};

export const loadLocationsForBooking = (options) => async (dispatch) => {
  const response = await LocationsApi.fetchLocationsForBooking(options);

  return dispatch(completeLoadLocations(response));
};

export const loadLocationsForCalendar = (options) => async (dispatch, getState) => {
  const { auth: { token }} = getState()
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/locations/get-for-calendar`, config);

  return dispatch(completeLoadLocations(response));
};

export const createLocation = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/locations/`, config);

  return dispatch(completeCreateLocation(response));
};

export const updateLocation = (id, data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/locations/${id}`, config);

  return dispatch(completeUpdateLocation(response));
};

export const deleteLocation = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/locations/${id}`, config);

  return dispatch(completeDeleteLocation(id));
};
