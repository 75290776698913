import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const insuranceProviders = createSlice({
  name: 'insuranceProviders',
  initialState: initialState,
  reducers: {
    completeLoadInsuranceProviders: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: makeOption(currValue),
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeCreateInsuranceProvider: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: makeOption(payload),
        },
        ids: [
          ...state.ids,
          payload.id,
        ],
      };
    },
  },
});

export const {
  completeLoadInsuranceProviders,
  completeCreateInsuranceProvider,
} = insuranceProviders.actions;

export default insuranceProviders.reducer;

export const loadInsuranceProviders = () => async (dispatch, getState) => {
  const { auth: { currentStaff } } = getState();
  const config = {
    params: {
      staffId: currentStaff.id || '',
    },
  };
  const response = await http.get(`${window.baseApiPath}/insurance-providers`, config);

  return dispatch(completeLoadInsuranceProviders(response));
};

export const loadInsuranceProvidersByCode = (code) => async (dispatch) => {
  const config = { params: { code } };
  const response = await http.get(`${window.baseApiPath}/insurance-providers`, config);

  return dispatch(completeLoadInsuranceProviders(response));
};

export const createInsuranceProvider = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/insurance-providers`, config);

  return dispatch(completeCreateInsuranceProvider(response));
};
