import http from '../shared_client_utils/http';
import axios from 'axios';

export default {
  exportPaymentReportInCSV: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/payments/export-payment-report-in-csv`, {
      params: options,
      headers: {
        Accept: 'text/csv',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
  exportPaymentReportInExcel: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/payments/export-payment-report-in-excel`, {
      params: options,
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
};
