import http from '../shared_client_utils/http';

export default {
  create: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/busy-times`, config);
  },
  update: (id, data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/busy-times/${id}`, config);
  },
  delete: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.delete(`${window.baseApiPath}/busy-times/${id}`, config);
  },
};
