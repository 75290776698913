import http from '../shared_client_utils/http';

export default {
  createTimeOffRequest: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/day-shift-requests/time-off-request`, config);
  },
  createPickUpRequest: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/day-shift-requests/pick-up-request`, config);
  },
  deleteRequest: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.delete(`${window.baseApiPath}/day-shift-requests/${id}`, config);
  },
  approveTimeOffRequest: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/day-shift-requests/${id}/approve-time-off-request`, config);
  },
  approvePickUpRequest: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/day-shift-requests/${id}/approve-pick-up-request`, config);
  },
  disapproveRequest: (id, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/day-shift-requests/${id}/disapprove`, config);
  },
};
