import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { ids: [], byId: {} };

const loginStaff = createSlice({
  name: 'loginStaff',
  initialState: initialState,
  reducers: {
    completeLoadLoginStaff: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: {
          ...currValue,
          ...makeOption(currValue, { labelColumn: 'fullName' }),
        },
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
  },
});

export const {
  completeLoadLoginStaff,
} = loginStaff.actions;

export default loginStaff.reducer;

export const loadLoginStaff = () => async (dispatch, getState) => {
  const { auth: { token, currentStaff } } = getState();
  const config = {
    params: {
      staffId: currentStaff.id,
    },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/staff/get-for-login`, config);

  return dispatch(completeLoadLoginStaff(response));
};
