import http from '../shared_client_utils/http';

export default {
  fetchStatusCounters: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/appointments/get-status-counters`, config);
  },
  fetchAppointmentsForInvoice: (clientId, auth) => {
    const { token } = auth;
    const config = {
      params: { clientId },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/appointments-for-invoice`, config);
  },
};
