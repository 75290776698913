import http from '../shared_client_utils/http';

export default {
  checkResetToken: (token) => {
    return http.get(`${window.baseApiPath}/accounts/check-reset-token?token=${token}`);
  },
  checkUserRegistrationToken: (token) => {
    return http.get(
      `${window.baseApiPath}/accounts/check-user-registration-token?token=${token}`,
    );
  },
  fetchUser: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/accounts/get-user`, config);
  },
  checkCurrentPassword: (password, auth) => {
    const { token } = auth;
    const config = {
      data: { password },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/accounts/check-current-password`, config);
  },
  updateUser: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/accounts`, config);
  },
};
