import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const taxes = createSlice({
  name: 'taxes',
  initialState: initialState,
  reducers: {
    completeLoadTaxes: (state, action) => {
      const ids = action.payload.map(({ id }) => id);
      const byId = action.payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeUpdateTaxes: (state, action) => {},
  },
});

export const {
  completeLoadTaxes,
  completeUpdateTaxes,
} = taxes.actions;

export default taxes.reducer;

export const loadTaxes = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/taxes`, config);

  return dispatch(completeLoadTaxes(response));
};

export const updateTaxes = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/taxes/multiple-update`, config);

  return dispatch(completeUpdateTaxes(response));
};
