import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const payments = createSlice({
  name: 'payments',
  initialState: initialState,
  reducers: {
    completeLoadSortablePayments: (state, { payload }) => {
      const ids = payload.payments.map(({ id }) => id);
      const byId = payload.payments.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
  },
});

export const {
  completeLoadSortablePayments,
} = payments.actions;

export default payments.reducer;

export const loadPaymentsForReports = (options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/payments/get-for-payment-reports`, config);

  return dispatch(completeLoadSortablePayments(response));
};
