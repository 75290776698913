import http from '../shared_client_utils/http';

export default {
  fetchNewRequest: (token, businessId) => {
    const config = {
      params: { token, businessId },
    };

    return http.get(
      `${window.baseApiPath}/online-payment-requests/get-new-request`,
      config,
    );
  },
  succeedRequest: (id, businessId) => {
    const config = {
      params: { businessId },
    };

    return http.put(`${window.baseApiPath}/online-payment-requests/${id}/succeed`, config);
  },
  markRequestForReview: (id, businessId) => {
    const config = {
      params: { businessId },
    };

    return http.put(
      `${window.baseApiPath}/online-payment-requests/${id}/mark-for-review`,
      config,
    );
  },
  fetchRequestBySuccessToken: (token, businessId) => {
    const config = {
      params: { token, businessId },
    };

    return http.get(
      `${window.baseApiPath}/online-payment-requests/get-by-success-token`,
      config,
    );
  },
  resetSuccessToken: (id, businessId) => {
    const config = {
      params: { businessId },
    };

    return http.put(
      `${window.baseApiPath}/online-payment-requests/${id}/reset-success-token`,
      config,
    );
  },
  completeRequest: (id, businessId) => {
    const config = {
      params: { businessId },
    };

    return http.put(`${window.baseApiPath}/online-payment-requests/${id}/complete`, config);
  },
};
