import http from '../shared_client_utils/http';

export default {
  modifyDayShift: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/day-shifts/modify`, config);
  },
};
