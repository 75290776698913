import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const usersActivities = createSlice({
  name: 'usersActivities',
  initialState,
  reducers: {
    completeLoadActivities: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedActivities: (state, { payload }) => {
      const ids = payload.activities.map(({ id }) => id);
      const byId = payload.activities.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
  },
});

export const {
  completeLoadActivities,
  completeLoadSortedActivities
} = usersActivities.actions;

export default usersActivities.reducer;

export const loadActivities = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/userActivities`, config);

  return dispatch(completeLoadActivities(response));
};

export const loadSortedActivities = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/userActivities/get-sorted`, config);

  return dispatch(completeLoadSortedActivities(response));
};
