import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { makeOption } from '../../../shared_client_utils/formUtils';
import { ServicesApi } from '../../../client_http_api';

const initialState = { byId: {}, ids: [] };

const services = createSlice({
  name: 'services',
  initialState: initialState,
  reducers: {
    completeLoadServices: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: {
          ...currValue,
          ...makeOption(currValue),
        },
      }), {});

      return { ...state, byId, ids };
    },
    completeCreateService: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...payload,
            ...makeOption(payload),
          },
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateService: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...payload,
            ...makeOption(payload),
          },
        },
      };
    },
    completeDeleteService: (state, { payload }) => {
      const { [payload]: deletedService, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadServices,
  completeCreateService,
  completeUpdateService,
  completeDeleteService,
} = services.actions;

export default services.reducer;

export const loadServices = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/services`, config);

  return dispatch(completeLoadServices(response));
};

export const loadServicesByCategory = (categoryId) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(
    `${window.baseApiPath}/services/by-category/${categoryId}`,
    config,
  );

  return dispatch(completeLoadServices(response));
};

export const loadServicesWithCategory = () => async (dispatch, getState) => {
  const { auth } = getState();
  const response = await ServicesApi.fetchServicesWithCategory(auth);

  return dispatch(completeLoadServices(response));
};

export const createService = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/services`, config);

  return dispatch(completeCreateService(response));
};

export const updateService = (data, id) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/services/${id}`, config);

  return dispatch(completeUpdateService(response));
};

export const deleteService = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/services/${id}`, config);

  return dispatch(completeDeleteService(id));
};
