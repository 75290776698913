import http from '../shared_client_utils/http';

export default {
  searchClients: (query, auth) => {
    const { token } = auth;
    const config = {
      params: { q: query },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/clients/search`, config);
  },
  searchClientsIgnoreGiven: (query, clientId, auth) => {
    const { token } = auth;
    const config = {
      params: { q: query, clientId },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/clients/search/ignore-given`, config);
  },
  searchStaff: (query, auth) => {
    const { token, currentStaff } = auth;
    const config = {
      params: {
        q: query,
        staffId: currentStaff.id,
      },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/staff/search`, config);
  },
  searchProducts: (query, auth) => {
    const { token } = auth;
    const config = {
      params: { q: query },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/products/search`, config);
  },
  searchByClientsAndInvoices: (query, auth) => { 
    const { token } = auth;
    const config = {
      params: { q: query },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/search/clients-and-invoices`, config);
  },
  searchInvoices: (query, auth) => {
    const { token } = auth;
    const config = {
      params: { q: query },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoices/search`, config);
  },
};
