import { combineReducers } from 'redux';

import authReducer from '../slices/authSlice';
import businessesReducer from '../../../shared_slices/businessesSlice';
import paymentTypesReducer from '../slices/paymentTypesSlice';
import taxesReducer from '../slices/taxesSlice';
import invoiceEmailsReducer from '../slices/invoiceEmailsSlice';
import bodyTagTypesReducer from '../slices/bodyTagTypesSlice';
import bodyTagsReducer from '../slices/bodyTagsSlice';
import positionsReducer from '../slices/positionsSlice';
import primaryReasonsReducer from '../slices/primaryReasonsSlice';
import relationshipsReducer from '../slices/relationshipsSlice';
import rolesReducer from '../slices/rolesSlice';
import invoicesReducer from '../slices/invoicesSlice';
import productCategoriesReducer from '../slices/productCategoriesSlice';
import productsReducer from '../slices/productsSlice';
import serviceCategoriesReducer from '../../../shared_slices/serviceCategoriesSlice';
import servicesReducer from '../slices/servicesSlice';
import giftCardsReducer from '../slices/giftCardsSlice';
import clientGiftCardsReducer from '../slices/clientGiftCardsSlice';
import clientCreditsReducer from '../slices/clientCreditsSlice';
import locationsReducer from '../../../shared_slices/locationsSlice';
import staffReducer from '../../../shared_slices/staffSlice';
import appointmentsReducer from '../slices/appointmentsSlice';
import clientsReducer from '../slices/clientsSlice';
import cancellationReasonsReducer from '../slices/cancellationReasonsSlice';
import waiversReducer from '../slices/waiversSlice';
import discountsReducer from '../slices/discountsSlice';
import timelyExportedFilesReducer from '../slices/timelyExportedFilesSlice';
import paymentsReducer from '../slices/paymentsSlice';
import packagesReducer from '../slices/packagesSlice';
import clientPackagesReducer from '../slices/clientPackagesSlice';
import clientFilesReducer from '../slices/clientFilesSlice';
import insuranceProvidersReducer from '../slices/insuranceProvidersSlice';
import soapFilesReducer from '../slices/soapFilesSlice';
import salesSettingsReducer from '../slices/salesSettingsSlice';
import loginStaffReducer from '../slices/loginStaffSlice';
import resourcesReducer from '../slices/resourcesSlice';
import usersActivitiesReducer from '../slices/usersActivitiesSlice';
import businessesDashboardReducer from '../slices/businessesDashboardSlice';
import googleReviewReducer from '../../../shared_slices/googleReviewSlice';
import messagingSettingsReducer from '../../../shared_slices/messagingSettings';

export default combineReducers({
  auth: authReducer,
  business: businessesReducer,
  paymentTypes: paymentTypesReducer,
  taxes: taxesReducer,
  invoiceEmails: invoiceEmailsReducer,
  bodyTagTypes: bodyTagTypesReducer,
  bodyTags: bodyTagsReducer,
  positions: positionsReducer,
  primaryReasons: primaryReasonsReducer,
  relationships: relationshipsReducer,
  roles: rolesReducer,
  invoices: invoicesReducer,
  productCategories: productCategoriesReducer,
  products: productsReducer,
  serviceCategories: serviceCategoriesReducer,
  services: servicesReducer,
  giftCards: giftCardsReducer,
  clientGiftCards: clientGiftCardsReducer,
  clientCredits: clientCreditsReducer,
  locations: locationsReducer,
  staff: staffReducer,
  appointments: appointmentsReducer,
  clients: clientsReducer,
  cancellationReasons: cancellationReasonsReducer,
  waivers: waiversReducer,
  discounts: discountsReducer,
  timelyExportedFiles: timelyExportedFilesReducer,
  payments: paymentsReducer,
  packages: packagesReducer,
  clientPackages: clientPackagesReducer,
  clientFiles: clientFilesReducer,
  insuranceProviders: insuranceProvidersReducer,
  soapFiles: soapFilesReducer,
  salesSettings: salesSettingsReducer,
  loginStaff: loginStaffReducer,
  resources: resourcesReducer,
  usersActivities: usersActivitiesReducer,
  businessesDashboard: businessesDashboardReducer,
  googleReview: googleReviewReducer,
  messagingSettings: messagingSettingsReducer
});
