import http from '../shared_client_utils/http';

export default {
  connect: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/stripe/connect`, config);
  },
  reAuth: (token) => {
    const config = {
      data: { token },
    }
    return http.post(`${window.baseApiPath}/stripe/reauth`, config);
  },
  onboardingCallback: (token) => {
    const config = {
      data: { token },
    }
    return http.post(`${window.baseApiPath}/stripe/onboarding-callback`, config);
  },
  disconnect: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/stripe/disconnect`, config);
  },
  createPaymentIntent: (data) => {
    const config = { data };
    return http.post(`${window.baseApiPath}/stripe/create-payment-intent`, config);
  },
  updatePaymentIntent: (data) => {
    const config = { data };
    return http.post(`${window.baseApiPath}/stripe/update-payment-intent`, config);
  },
};
