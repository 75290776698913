import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const relationships = createSlice({
  name: 'relationships',
  initialState: initialState,
  reducers: {
    completeLoadRelationships: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: makeOption(currValue),
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
  },
});

export const {
  completeLoadRelationships,
} = relationships.actions;

export default relationships.reducer;

export const loadRelationships = () => async (dispatch) => {
  const response = await http.get(`${window.baseApiPath}/relationships`, {});

  return dispatch(completeLoadRelationships(response));
};
