import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const products = createSlice({
  name: 'products',
  initialState: initialState,
  reducers: {
    completeLoadProducts: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: currValue,
      }), {});
      return { ...state, byId, ids };
    },
    completeCreateProduct: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [
          ...state.ids,
          payload.id,
        ],
      };
    },
    completeUpdateProduct: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteProduct: (state, { payload }) => {
      const { [payload]: deletedProduct, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadProducts,
  completeCreateProduct,
  completeUpdateProduct,
  completeDeleteProduct,
} = products.actions;

export default products.reducer;

export const loadProducts = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/products`, config);

  return dispatch(completeLoadProducts(response));
};

export const loadProductsByCategory = (categoryId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/products/by-category/${categoryId}`, config);

  return dispatch(completeLoadProducts(response));
};

export const createProduct = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/products`, config);

  return dispatch(completeCreateProduct(response));
};

export const updateProduct = (id, data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/products/${id}`, config);

  return dispatch(completeUpdateProduct(response));
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/products/${id}`, config);

  return dispatch(completeDeleteProduct(id));
};
