export const loadState = () => {
  try {
    let serializedState = sessionStorage.getItem("state");
    if (!serializedState)serializedState = localStorage.getItem('state') || {};
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({ ...state });
    sessionStorage.setItem('state', serializedState);
    localStorage.setItem('state', serializedState);

  } catch (error) {
    // Ignore for now
  }
};
