import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { SettingsApi } from '../../../client_http_api';

const initialState = {
  invoiceTitle: '',
  businessRegistrationNumber: '',
  businessRegistrationType: '',
  nextInvoiceNumber: 0,  
};

const salesSettings = createSlice({
  name: 'salesSettings',
  initialState: initialState,
  reducers: {
    completeChangeSalesSettings: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const {
  completeChangeSalesSettings,
} = salesSettings.actions;

export default salesSettings.reducer;

export const loadSalesSettings = () => async (dispatch, getState) => {
  const { auth } = getState();
  const response = await SettingsApi.fetchSalesSettings(auth);

  return dispatch(completeChangeSalesSettings(response));
};

export const saveSalesSettings = (data) => async (dispatch, getState) => {
  const { auth } = getState();
  const response = await SettingsApi.updateSalesSettings(data, auth);

  return dispatch(completeChangeSalesSettings(response));
};
