import http from '../shared_client_utils/http';

export default {
  fetchClientHistory: (clientId, auth, appointmentId) => {
    const { token } = auth;
    const config = {
      params: {clientId, ...(appointmentId ? {appointmentId} : {})},
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/client-history/by-client`, config);
  },
};
