import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const timelyExportedFiles = createSlice({
  name: 'timelyExportedFiles',
  initialState: initialState,
  reducers: {
    completeLoadTimelyExportedFiles: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeCreateTimelyExportedFiles: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        ids: [...state.ids, ...ids],
        byId: {
          ...state.byId,
          ...byId,
        },
      };
    },
  },
});

export const {
  completeLoadTimelyExportedFiles,
  completeCreateTimelyExportedFiles,
} = timelyExportedFiles.actions;

export default timelyExportedFiles.reducer;

export const loadTimelyExportedFiles = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/timely-exported-files`, config);

  return dispatch(completeLoadTimelyExportedFiles(response));
};

export const multipleCreateTimelyExportedFiles = data => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/timely-exported-files`, config);

  return dispatch(completeCreateTimelyExportedFiles(response));
};
