import http from '../shared_client_utils/http';

export default {
  fetchIntakeSettings: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/settings/intake`, config);
  },
  updateIntakeSettings: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/settings/intake`, config);
  },
  fetchSalesSettings: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/settings/sales`, config);
  },
  updateSalesSettings: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/settings/sales`, config);
  },
  fetchBookingSettings: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/settings/booking`, config);
  },
  updateBookingSettings: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/settings/booking`, config);
  },
  fetchSmsSettings: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/settings/sms`, config);
  },
  updateSmsSettings: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/settings/sms`, config);
  },
  fetchCalendarSettings: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/settings/calendar`, config);
  },
  updateCalendarSettings: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/settings/calendar`, config);
  },
}
