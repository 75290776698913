import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const bodyTagTypes = createSlice({
  name: 'bodyTagTypes',
  initialState: initialState,
  reducers: {
    completeLoadBodyTagTypes: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: makeOption(currValue),
      }), {});
      return {
        ...state,
        byId,
        ids,
      };
    },
  },
});

export const {
  completeLoadBodyTagTypes,
} = bodyTagTypes.actions;

export default bodyTagTypes.reducer;

export const loadBodyTagTypes = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = { headers: { Authorization: `Bearer ${token}` }};
  const response = await http.get(`${window.baseApiPath}/body-tag-types`, config);

  return dispatch(completeLoadBodyTagTypes(response));
};
