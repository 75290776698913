import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const discounts = createSlice({
  name: 'discounts',
  initialState: initialState,
  reducers: {
    completeLoadDiscounts: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedDiscounts: (state, { payload }) => {
      const ids = payload.discounts.map(({ id }) => id);
      const byId = payload.discounts.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeCreateDiscount: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateDiscount: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteDiscount: (state, { payload }) => {
      const { [payload]: deletedDiscount, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadDiscounts,
  completeLoadSortedDiscounts,
  completeCreateDiscount,
  completeUpdateDiscount,
  completeDeleteDiscount,
} = discounts.actions;

export default discounts.reducer;

export const loadDiscounts = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/discounts`, config);

  return dispatch(completeLoadDiscounts(response));
};

export const loadSortedDiscounts = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/discounts/get-sorted`, config);

  return dispatch(completeLoadSortedDiscounts(response));
};

export const createDiscount = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/discounts`, config);

  return dispatch(completeCreateDiscount(response));
};

export const updateDiscount = (id, data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/discounts/${id}`, config);

  return dispatch(completeUpdateDiscount(response));
};

export const deleteDiscount = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/discounts/${id}`, config);

  return dispatch(completeDeleteDiscount(id));
};
