import http from '../shared_client_utils/http';

export default {
  fetchDayShiftsForRoster: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff/get-with-day-shifts-for-roster`, config);
  },
  fetchDayShiftsForDashboard: (id, options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff/${id}/get-with-day-shifts-for-dashboard`, config);
  },
  fetchStaffForCalendar: (options, auth, key) => {
    const { token } = auth;
    const config = {
      params: {
        ...options,
        forMobileView: key,
      },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/staff/get-for-calendar`, config);
  },
  fetchStaffForDashboard: (locationId, auth) => {
    const { token } = auth;
    const config = {
      params: { locationId },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/staff/get-for-dashboard`, config);
  },
  fetchUserStaff: (email, password) => {
    const config = { data: { email, password } };

    return http.post(`${window.baseApiPath}/staff/get-user-staff`, config);
  },
  fetchAppointmentsCountByDate: (id, options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(
      `${window.baseApiPath}/staff/${id}/get-appointments-count-by-date`,
      config,
    );
  },
  startOneShotLogic: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff/check-alias/check-staff-alias`, config);
  },
  getStaffLinkRedirectCount: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff/short-links/get-redirect-count`, config);
  },
};
