import http from '../shared_client_utils/http';

export default {
  fetchDiscountTypes: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/discount-types`, config);
  },
};
