import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    completeLoadRoles: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: makeOption(currValue),
      }), {});

      return {
        ...state,
        allRoles: payload || [],
        byId,
        ids,
      };
    },
  },
});

export const {
  completeLoadRoles,
} = roles.actions;

export default roles.reducer;

export const loadRoles = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = { headers: { Authorization: `Bearer ${token}` }};
  const response = await http.get(`${window.baseApiPath}/roles`, config);

  return dispatch(completeLoadRoles(response));
};

export const loadEmployeeRoles = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = { headers: { Authorization: `Bearer ${token}` }};
  const response = await http.get(`${window.baseApiPath}/roles/get-employee-roles`, config);

  return dispatch(completeLoadRoles(response));
};
