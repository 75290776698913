/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';

import http from '../shared_client_utils/http';

const initialState = {
  isReviewLinkOn: false,
  googleReviewType: 'appointments',
  appointmentsCount: 0,
  revenue: 0,
  googleReviewTemplate: '',
};

const googleReview = createSlice({
  name: 'googleReview',
  initialState,
  reducers: {
    completeLoadGoogleReview: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const {
  completeLoadGoogleReview,
} = googleReview.actions;

export default googleReview.reducer;

export const loadGoogleReview = params => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/google-review`, config);

  return dispatch(completeLoadGoogleReview(response));
};

export const saveOrUpdateGoogleReview = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/google-review`, config);

  return dispatch(completeLoadGoogleReview(response));
};
