import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const resources = createSlice({
  name: 'resources',
  initialState: initialState,
  reducers: {
    completeLoadResources: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedResources: (state, { payload }) => {
      const ids = payload.resources.map(({ id }) => id);
      const byId = payload.resources.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeCreateResource: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateResource: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteResource: (state, { payload }) => {
      const { [payload]: deletedResource, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadResources,
  completeLoadSortedResources,
  completeCreateResource,
  completeUpdateResource,
  completeDeleteResource,
} = resources.actions;

export default resources.reducer;

export const loadResources = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/resources`, config);

  return dispatch(completeLoadResources(response));
};

export const loadSortedResources = (options) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/resources/get-sorted`, config);

  return dispatch(completeLoadSortedResources(response));
};

export const createResource = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/resources`, config);

  return dispatch(completeCreateResource(response));
};

export const updateResource = (id, data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/resources/${id}`, config);

  return dispatch(completeUpdateResource(response));
};

export const deleteResource = (id) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/resources/${id}`, config);

  return dispatch(completeDeleteResource(id));
};
