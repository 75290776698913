import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { bySoapNoteId: {} };

const bodyTags = createSlice({
  name: 'bodyTags',
  initialState: initialState,
  reducers: {
    completeLoadBodyTags: (state, action) => {
      const { response, soapNoteId } = action.payload;

      const ids = response.map(({ id }) => id);
      const byId = response.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        bySoapNoteId: {
          ...state.bySoapNoteId,
          [soapNoteId]: {
            byId,
            ids,
          },
        },
      };
    },
  },
});

export const {
  completeLoadBodyTags,
} = bodyTags.actions;

export default bodyTags.reducer;

export const loadBodyTags = (soapNoteId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: { soapNoteId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/body-tags`, config);

  return dispatch(completeLoadBodyTags({ soapNoteId, response }));
};
