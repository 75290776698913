import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const clientGiftCards = createSlice({
  name: 'clientGiftCards',
  initialState: initialState,
  reducers: {
    completeLoadClientGiftCards: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});
      return { ...state, byId, ids };
    },
    completeLoadSortedClientGiftCards: (state, { payload }) => {
      const ids = payload.clientGiftCards.map(({ id }) => id);
      const byId = payload.clientGiftCards.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeActivateClientGiftCards: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeactivateClientGiftCards: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
  },
});

export const {
  completeLoadClientGiftCards,
  completeLoadSortedClientGiftCards,
  completeActivateClientGiftCards,
  completeDeactivateClientGiftCards,
} = clientGiftCards.actions;

export default clientGiftCards.reducer;

export const loadClientGiftCards = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/client-gift-cards`, config);

  return dispatch(completeLoadClientGiftCards(response));
};

export const loadSortedClientGiftCards = options => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/client-gift-cards/get-sorted`, config);

  return dispatch(completeLoadSortedClientGiftCards(response));
};

export const activateClientGiftCard = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/client-gift-cards/${id}/activate`, config);

  return dispatch(completeActivateClientGiftCards(response));
};

export const deactivateClientGiftCard = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/client-gift-cards/${id}/deactivate`, config);

  return dispatch(completeDeactivateClientGiftCards(response));
};
