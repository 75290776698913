import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const invoiceEmails = createSlice({
  name: 'invoiceEmails',
  initialState: initialState,
  reducers: {
    completeLoadEmails: (state, action) => {
      const ids = action.payload.map(({ id }) => id);
      const byId = action.payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeSendEmail: (state, action) => {
      const invoiceEmail = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [invoiceEmail.id]: invoiceEmail,
        },
        ids: [...state.ids, invoiceEmail.id],
      };
    },
  },
});

export const {
  completeLoadEmails,
  completeSendEmail,
} = invoiceEmails.actions;

export default invoiceEmails.reducer;

export const loadInvoiceEmails = invoiceId => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: { invoiceId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/invoice-emails`, config);
  return dispatch(completeLoadEmails(response));
};

export const sendInvoiceEmail = data => async (dispatch, getState) => {
  const { auth: { token, currentStaff }} = getState();
  const config = {
    data,
    params: {
      staffId: currentStaff.id,
    },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/invoice-emails/send`, config);
  return dispatch(completeSendEmail(response));
};
