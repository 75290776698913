import http from '../shared_client_utils/http';

export default {
  getClientCreditBalance: (clientId, auth) => {
    const { token } = auth;
    const config = {
      params: { clientId },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/client-credits/get-balance`, config);
  },
};
