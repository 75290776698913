import http from '../shared_client_utils/http';

export default {
  fetchLocations: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/locations`, config);
  },
  fetchLocationsForBooking: (options) => {
    const config = { params: options };

    return http.get(`${window.baseApiPath}/locations/get-for-booking`, config);
  },
};
