import axios from 'axios';

import http from '../shared_client_utils/http';

export default {
  fetchSOAPNoteByAppointment: (appointmentId, auth) => {
    const { token } = auth;
    const config = {
      params: { appointmentId },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/soap-notes/get-by-appointment`, config);
  },
  createSOAPNote: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/soap-notes`, config);
  },
  updateSOAPNote: (id, data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/soap-notes/${id}`, config);
  },
  updateAndLockSOAPNote: (id, data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/soap-notes/${id}/update-and-lock`, config);
  },
  unlockSOAPNote: (id, options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/soap-notes/${id}/unlock`, config);
  },
  deleteSOAPNote: (id, options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.delete(`${window.baseApiPath}/soap-notes/${id}`, config);
  },
  fetchPdf: (id, auth, isHtml) => {
    const { token } = auth;

    const config = isHtml ? {
      params: { isHtml: 'ok' },
      headers: {
        Authorization: `Bearer ${token}`
      },
    } : {
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${token}`
      },
      responseType: 'arraybuffer',
    }

    return axios.get(`${window.baseApiPath}/soap-notes/${id}/pdf`, config);
  },
  fetchClientAppointments: (clientId, auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/appointments/get-for-chart/${clientId}`, config);
  },
};
