import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const positions = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    completeLoadPositions: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: makeOption(currValue),
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeAddPosition: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: makeOption(payload),
        },
        ids: [...state.ids, payload.id],
      };
    },
  },
});

export const {
  completeLoadPositions,
  completeAddPosition,
} = positions.actions;

export default positions.reducer;

export const loadPositions = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/positions`, config);

  return dispatch(completeLoadPositions(response));
};

export const loadStaffPositions = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/positions/staff-positions`, config);

  dispatch(completeLoadPositions(response));
  return response
};

export const addPosition = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/positions`, config);

  return dispatch(completeAddPosition(response));
};
