// Set Sunday as the first day of week
// In the main calendar and in date picker component
import moment from 'moment-timezone'
import {registerLocale, setDefaultLocale} from 'react-datepicker'
import enCa from 'date-fns/locale/en-CA'

moment.updateLocale('en', {
  week: { dow: 0 },
});
registerLocale('en-ca', enCa);
setDefaultLocale('en-ca');
