import axios from 'axios';

import http from '../shared_client_utils/http';

export default {
  fetchKPIs: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff-kpis`, config);
  },
  fetchReports: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff-kpis/get-reports`, config);
  },
  calculateReports: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff-kpis/calculate-reports`, config);
  },
  exportReportInCSV: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/staff-kpis/export-report-in-csv`, {
      params: options,
      headers: {
        Accept: 'text/csv',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
  exportReportInExcel: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/staff-kpis/export-report-in-excel`, {
      params: options,
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },

  exportFullStaffReportInExcel: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/staff-kpis/export-full-staff-report-in-excel`, {
      params: options,
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },

  calculateFullReports: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/staff-kpis/calculate-full-reports`, config);
  },
};
