import http from '../shared_client_utils/http';

export default {
  activateRecoveryItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/recovery-items/activate-item`, config);
  },
  updateRecoveryItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/recovery-items/update-item`, config);
  },
  createRecoveryItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.post(`${window.baseApiPath}/recovery-items/create-item`, config);
  },
  deleteRecoveryItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.post(`${window.baseApiPath}/recovery-items/delete-item`, config);
  },
};
