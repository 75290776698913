import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const clientPackages = createSlice({
  name: 'clientPackages',
  initialState: initialState,
  reducers: {
    completeLoadSortableClientPackages: (state, { payload }) => {
      const ids = payload.clientPackages.map(({ id }) => id);
      const byId = payload.clientPackages.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeDeleteClientPackage: (state, { payload }) => {
      const { [payload]: deletedClientPackage, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadSortableClientPackages,
  completeDeleteClientPackage,
} = clientPackages.actions;

export default clientPackages.reducer;

export const loadSortableClientPackages = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/client-packages/get-sortable`, config);

  return dispatch(completeLoadSortableClientPackages(response));
};

export const deleteClientPackage = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/client-packages/${id}`, config);

  return dispatch(completeDeleteClientPackage(id));
};
