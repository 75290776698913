import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const clientCredits = createSlice({
  name: 'clientCredits',
  initialState: initialState,
  reducers: {
    completeLoadSortableClientCredits: (state, { payload }) => {
      const ids = payload.clientCredits.map(({ id }) => id);
      const byId = payload.clientCredits.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeCreateClientCredit: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [
          ...state.ids,
          payload.id,
        ],
      };
    },
    completeUpdateClientCredit: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteClientCredit: (state, { payload }) => {
      const { [payload]: deletedGiftCard, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadSortableClientCredits,
  completeCreateClientCredit,
  completeUpdateClientCredit,
  completeDeleteClientCredit,
} = clientCredits.actions;

export default clientCredits.reducer;

export const loadSortableClientCredits = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/client-credits/get-sortable`, config);

  return dispatch(completeLoadSortableClientCredits(response));
};

export const createClientCredit = (data, options) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/client-credits`, config);

  return dispatch(completeCreateClientCredit(response));
};

export const updateClientCredit = (id, data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/client-credits/${id}`, config);

  return dispatch(completeUpdateClientCredit(response));
};

export const cancelClientCredit = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/client-credits/${id}/cancel`, config);

  return dispatch(completeUpdateClientCredit(response));
};

export const deleteClientCredit = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/client-credits/${id}`, config);

  return dispatch(completeDeleteClientCredit(id));
};
