import axios from 'axios';

const getErrorMessage = (status, statusText, headers, message) => {
  if (status === 401) {
    return headers["www-authenticate"] || statusText;
  }
  return message || statusText;
};

function ServerError(status, statusText, headers, data) {
  this.name = data?.name || 'Unexpected error';
  this.message = getErrorMessage(status, statusText, headers, data?.message || 'Unexpected error, please try again');
  this.status = status;
  this.body = data?.body;
}
ServerError.prototype = Error.prototype;

const request = async (method, url, config = {}) => {
  const { headers, data, ...restConfig } = config;
  const body = data instanceof FormData ? data : JSON.stringify(data);
  if (!(data instanceof FormData)) {
    headers["Content-Type"] = "application/json;charset=UTF-8";
  }
  return axios({
    ...restConfig,
    method,
    url,
    headers: {
      ...headers,
      Accept: 'application/json',
    },
    data: body,
  })
    .then(response => response.data)
    .catch((error) => {
      // Need refactoring. Remove creating custom error
      if (error.response) {
        const { status, statusText, headers, data } = error.response;
        throw new ServerError(status, statusText, headers, data);
      }
      throw new Error(error.message);
    });
};

const encodeQueryParams = (url, params) => {
  const queryParams = [];
  Object.keys(params).forEach((param) => {
    queryParams.push(`${param}=${encodeURIComponent(params[param])}`);
  });
  if (queryParams.length > 0) url += `?${queryParams.join('&')}`;
  return url;
};

export default {
  get(url, { params = {}, headers = {} } = {}) {
    return request("get", encodeQueryParams(url, params), { headers });
  },
  post(url, { data = {}, params = {}, headers = {} } = {}) {
    return request("post", encodeQueryParams(url, params), { data, headers });
  },
  put(url, { data = {}, params = {}, headers = {} } = {}) {
    return request("put", encodeQueryParams(url, params), { data, headers });
  },
  delete(url, { data = {}, params = {}, headers = {} } = {}) {
    return request("delete", encodeQueryParams(url, params), { data, headers });
  },
};
