import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const cancellationReasons = createSlice({
  name: 'cancellationReasons',
  initialState: initialState,
  reducers: {
    completeLoadCancellationReasons: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeUpdateCancellationReasons: (state, { payload }) => {},
  },
});

export const {
  completeLoadCancellationReasons,
  completeUpdateCancellationReasons,
} = cancellationReasons.actions;

export default cancellationReasons.reducer;

export const loadCancellationReasons = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/cancellation-reasons`, config);

  return dispatch(completeLoadCancellationReasons(response));
};

export const loadCancellationReasonsForCustomer = (businessId) => async (dispatch) => {
  const response = await http.get(
    `${window.baseApiPath}/cancellation-reasons/get-for-customer`,
    { params: { businessId }},
  );

  return dispatch(completeLoadCancellationReasons(response));
};

export const updateCancellationReasons = (data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/cancellation-reasons/multiple-update`, config);

  return dispatch(completeUpdateCancellationReasons(response));
};
