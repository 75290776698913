import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const waivers = createSlice({
  name: 'waivers',
  initialState: initialState,
  reducers: {
    completeLoadWaivers: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
  },
});

export const {
  completeLoadWaivers,
} = waivers.actions;

export default waivers.reducer;

export const loadWaiversForClient = clientId => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: { clientId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/waivers/get-for-client`, config);

  return dispatch(completeLoadWaivers(response));
};
