import http from '../shared_client_utils/http';

export default {
  fetchResourceItemsForAppointment: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/resource-items/get-for-appointment`, config);
  },
};
