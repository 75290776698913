import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const primaryReasons = createSlice({
  name: 'primaryReasons',
  initialState: initialState,
  reducers: {
    completeLoadPrimaryReasons: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: makeOption(currValue),
      }), {});
      return {
        ...state,
        byId,
        ids,
      };
    },
  },
});

export const {
  completeLoadPrimaryReasons,
} = primaryReasons.actions;

export default primaryReasons.reducer;

export const loadPrimaryReasons = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = { headers: { Authorization: `Bearer ${token}` }};
  const response = await http.get(`${window.baseApiPath}/primary-reasons`, config);

  return dispatch(completeLoadPrimaryReasons(response));
};
