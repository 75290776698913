import http from '../shared_client_utils/http';

export default {
  activateBuzzStaffItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/buzz-staff/activate-item`, config);
  },
  updateBuzzStaffItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.put(`${window.baseApiPath}/buzz-staff/update-item`, config);
  },
  createBuzzStaffItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.post(`${window.baseApiPath}/buzz-staff/create-item`, config);
  },
  deleteBuzzStaffItem: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.post(`${window.baseApiPath}/buzz-staff/delete-item`, config);
  },
};
