import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byId: {}, ids: [] };

const packages = createSlice({
  name: 'packages',
  initialState: initialState,
  reducers: {
    completeLoadPackages: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeLoadSortedPackages: (state, { payload }) => {
      const ids = payload.packages.map(({ id }) => id);
      const byId = payload.packages.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeCreatePackage: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdatePackage: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeletePackage: (state, { payload }) => {
      const { [payload]: deletedPackage, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadPackages,
  completeLoadSortedPackages,
  completeCreatePackage,
  completeUpdatePackage,
  completeDeletePackage,
} = packages.actions;

export default packages.reducer;

export const loadPackages = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/packages`, config);

  return dispatch(completeLoadPackages(response));
};

export const loadSortedPackages = options => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/packages/get-sorted`, config);

  return dispatch(completeLoadSortedPackages(response));
};

export const createPackage = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/packages`, config);

  return dispatch(completeCreatePackage(response));
};

export const updatePackage = (id, data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/packages/${id}`, config);

  return dispatch(completeUpdatePackage(response));
};

export const deletePackage = id => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/packages/${id}`, config);

  return dispatch(completeDeletePackage(id));
};
