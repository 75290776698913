import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";
import { makeOption } from '../../../shared_client_utils/formUtils';

const initialState = { byId: {}, ids: [] };

const productCategories = createSlice({
  name: 'productCategories',
  initialState: initialState,
  reducers: {
    completeLoadProductCategories: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: currValue,
      }), {});
      return { ...state, byId, ids };
    },
    completeCreateProductCategory: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateProductCategory: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteProductCategory: (state, { payload }) => {
      const { [payload]: deletedCategory, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadProductCategories,
  completeCreateProductCategory,
  completeUpdateProductCategory,
  completeDeleteProductCategory,
} = productCategories.actions;

export default productCategories.reducer;

export const loadProductCategories = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/product-categories`, config);

  return dispatch(completeLoadProductCategories(response));
};

export const createProductCategory = data => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/product-categories`, config);

  return dispatch(completeCreateProductCategory(response));
};

export const updateProductCategory = (data, id) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/product-categories/${id}`, config);

  return dispatch(completeUpdateProductCategory(response));
};

export const deleteProductCategory = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/product-categories/${id}`, config);

  return dispatch(completeDeleteProductCategory(id));
};

