const initialState = (localState = {}) => ({
  auth: {
    token: '',
    userId: null,
    currentStaff: {},
    ...localState.auth,
  },
  staff: {
    byId: {},
    ids: [],
    ...localState.staff,
  },
  loginStaff: {
    byId: {},
    ids: [],
    ...localState.loginStaff,
  },
  clients: {
    byId: {},
    ids: [],
    ...localState.clients,
  },
  business: {
    ...localState.business,
  },
  roles: {
    byId: {},
    ids: [],
    ...localState.roles,
  },
  positions: {
    byId: {},
    ids: [],
    ...localState.positions,
  },
  salesSettings: {
    ...localState.salesSettings,
  },
  insuranceProviders: {
    byId: {},
    ids: [],
    ...localState.insuranceProviders,
  },
  relationships: {
    byId: {},
    ids: [],
    ...localState.relationships,
  },
  appointments: {
    byId: {},
    ids: [],
    ...localState.appointments,
  },
  primaryReasons: {
    byId: {},
    ids: [],
    ...localState.primaryReasons,
  },
  bodyTagTypes: {
    byId: {},
    ids: [],
    ...localState.bodyTagTypes,
  },
  bodyTags: {
    bySoapNoteId: {},
    ...localState.bodyTags,
  },
  soapFiles: {
    bySoapNoteId: {},
    byClientId: {},
    ...localState.soapFiles,
  },
  services: {
    byId: {},
    ids: [],
    ...localState.services,
  },
  clientFiles: {
    byClientId: {},
    ...localState.clientFiles,
  },
  invoices: {
    byId: {},
    ids: [],
    ...localState.invoices,
  },
  invoiceEmails: {
    byId: {},
    ids: [],
    ...localState.invoiceEmails,
  },
  paymentTypes: {
    byId: {},
    ids: [],
    ...localState.paymentTypes,
  },
  payments: {
    byId: {},
    ids: [],
    ...localState.payments,
  },
  discounts: {
    byId: {},
    ids: [],
    ...localState.discounts,
  },
  taxes: {
    byId: {},
    ids: [],
    ...localState.taxes,
  },
  productCategories: {
    byId: {},
    ids: [],
    ...localState.productCategories,
  },
  products: {
    byId: {},
    ids: [],
    ...localState.products,
  },
  giftCards: {
    byId: {},
    ids: [],
    ...localState.giftCards,
  },
  clientGiftCards: {
    byId: {},
    ids: [],
    ...localState.clientGiftCards,
  },
  clientCredits: {
    byId: {},
    ids: [],
    ...localState.clientCredits,
  },
  locations: {
    byId: {},
    ids: [],
    ...localState.locations,
  },
  cancellationReasons: {
    byId: {},
    ids: [],
    ...localState.cancellationReasons,
  },
  waivers: {
    byId: {},
    ids: [],
    ...localState.waivers,
  },
  packages: {
    byId: {},
    ids: [],
    ...localState.packages,
  },
  clientPackages: {
    byId: {},
    ids: [],
    ...localState.clientPackages,
  },
  resources: {
    byId: {},
    ids: [],
    ...localState.resources,
  },
});

export default initialState;
