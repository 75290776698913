import { createSlice } from '@reduxjs/toolkit';

import http from "../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const serviceCategories = createSlice({
  name: 'serviceCategories',
  initialState,
  reducers: {
    completeLoadServiceCategories: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result,
        [currValue.id]: currValue,
      }), {});
      return { ...state, byId, ids };
    },
    completeCreateServiceCategory: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [...state.ids, payload.id],
      };
    },
    completeUpdateServiceCategory: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteServiceCategory: (state, { payload }) => {
      const { [payload]: deletedCategory, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadServiceCategories,
  completeCreateServiceCategory,
  completeUpdateServiceCategory,
  completeDeleteServiceCategory,
} = serviceCategories.actions;

export default serviceCategories.reducer;

export const loadServiceCategories = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/service-categories`, config);

  return dispatch(completeLoadServiceCategories(response));
};

export const loadCategoriesWithServices = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/service-categories/get-with-services`, config);

  return dispatch(completeLoadServiceCategories(response));
};

export const loadCategoriesWithServicesForBooking = options => {
  return async (dispatch, getState) => {
    const response = await http.get(
      `${window.baseApiPath}/service-categories/get-with-services-for-booking`,
      { params: options },
    );

    return dispatch(completeLoadServiceCategories(response));
  };
};

export const createServiceCategory = data => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/service-categories`, config);

  return dispatch(completeCreateServiceCategory(response));
};

export const updateServiceCategory = (data, id) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/service-categories/${id}`, config);

  return dispatch(completeUpdateServiceCategory(response));
};

export const deleteServiceCategory = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/service-categories/${id}`, config);

  return dispatch(completeDeleteServiceCategory(id));
};

