import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { bySoapNoteId: {}, byClientId: {} };

const soapFiles = createSlice({
  name: 'soapFiles',
  initialState: initialState,
  reducers: {
    completeLoadSoapFiles: (state, action) => {
      const { soapFiles, soapNoteId } = action.payload;

      const ids = soapFiles.map(({ id }) => id);
      const byId = soapFiles.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        bySoapNoteId: {
          ...state.bySoapNoteId,
          [soapNoteId]: {
            byId,
            ids,
          },
        },
      };
    },
    completeLoadSoapFilesByClient: (state, action) => {
      const { soapFiles, clientId } = action.payload;

      const ids = soapFiles.map(({ id }) => id);
      const byId = soapFiles.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byClientId: {
          ...state.byClientId,
          [clientId]: {
            byId,
            ids,
          },
        },
      };
    },
    completeCreateSoapFile: (state, action) => {
      const { soapFile, soapNoteId } = action.payload;

      const soapFiles = state.bySoapNoteId[soapNoteId];
      const byId = {
        ...soapFiles.byId,
        [soapFile.id]: soapFile,
      };
      const ids = [...soapFiles.ids, soapFile.id];

      return {
        ...state,
        bySoapNoteId: {
          ...state.bySoapNoteId,
          [soapNoteId]: {
            byId,
            ids,
          },
        },
      };
    },
    completeUpdateSoapFile: (state, action) => {
      const { soapFile, soapNoteId } = action.payload;

      const soapFiles = state.bySoapNoteId[soapNoteId];
      const byId = {
        ...soapFiles.byId,
        [soapFile.id]: soapFile,
      };

      return {
        ...state,
        bySoapNoteId: {
          ...state.bySoapNoteId,
          [soapNoteId]: {
            byId,
            ids: soapFiles.ids,
          },
        },
      };
    },
    completeDeleteSoapFile: (state, action) => {
      const { id, soapNoteId } = action.payload;

      const soapFiles = state.bySoapNoteId[soapNoteId];
      const { [id]: deletedSoapFile, ...byId } = soapFiles.byId;

      return {
        ...state,
        bySoapNoteId: {
          ...state.bySoapNoteId,
          [soapNoteId]: {
            byId,
            ids: soapFiles.ids.filter((fileId) => fileId !== id),
          },
        },
      };
    },
  },
});

export const {
  completeLoadSoapFiles,
  completeLoadSoapFilesByClient,
  completeCreateSoapFile,
  completeUpdateSoapFile,
  completeDeleteSoapFile,
} = soapFiles.actions;

export default soapFiles.reducer;

export const loadSoapFiles = (soapNoteId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: { soapNoteId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const soapFiles = await http.get(`${window.baseApiPath}/soap-files`, config);

  return dispatch(completeLoadSoapFiles({ soapNoteId, soapFiles }));
};

export const loadSoapFilesByClient = (clientId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: { clientId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const soapFiles = await http.get(`${window.baseApiPath}/soap-files-by-client`, config);

  return dispatch(completeLoadSoapFilesByClient({ clientId, soapFiles }));
};

export const createSoapFile = (data, soapNoteId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const soapFile = await http.post(`${window.baseApiPath}/soap-files`, config);

  return dispatch(completeCreateSoapFile({ soapNoteId, soapFile }));
};

export const updateSoapFile = (id, data, soapNoteId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const soapFile = await http.put(`${window.baseApiPath}/soap-files/${id}`, config);

  return dispatch(completeUpdateSoapFile({ soapNoteId, soapFile }));
};

export const deleteSoapFile = (id, soapNoteId, appointmentId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: { soapNoteId, appointmentId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/soap-files/${id}`, config);

  return dispatch(completeDeleteSoapFile({ soapNoteId, id }));
};
