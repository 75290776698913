import { createTheme } from '@mui/material/styles';
import { red, green } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      light: '#FEEDD6',
      main: '#F5A623',
      dark: '#F29201',
      contrastText: '#ffffff',
    },
    secondary: {
      light: green[100],
      main: '#2FD475',
      contrastText: '#ffffff',
    },
    error: {
      light: red[100],
      main: '#FB4841',
      dark: red[800],
      contrastText: '#ffffff',
    },
    disabled: {
      main: 'rgba(0, 0, 0, 0.26)',
    },
  },
  // typography: {
    // fontSize: 14,
    // htmlFontSize: 16,
  // },
});

export const navbarHeightMultiplier = 8;

export const calendarSidebarWidth = theme.spacing(55/2);

export const maxWidth = '2000px';

export default theme;
