import http from '../shared_client_utils/http';

export default {
  getValidClientGiftCardByCode: (code, currentClientTime, auth) => {
    const { token } = auth;
    const config = {
      params: { code, currentClientTime },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/client-gift-cards/get-valid-by-code`, config);
  },
  getValidClientGiftCardForOnlinePayment: (options) => {
    const config = {
      params: options,
    };

    return http.get(
      `${window.baseApiPath}/client-gift-cards/get-valid-for-online-payment`,
      config,
    );
  },
};
