import http from '../shared_client_utils/http';

export default {
  sendGoogleReviewTestEmail: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/google-review/send-test-email`, config);
  },
};
