import axios from 'axios';
import http from '../shared_client_utils/http';

export default {
  callback: (code, auth) => {
    const { token } = auth;
    const config = {
      data: { code },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/mailchimp/callback`, config);
  },
  disconnect: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/mailchimp/disconnect`, config);
  },
  fetchAudiences: (auth) => {
    const { token } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/mailchimp/fetch-audiences`, config);
  },
  checkSubscription: (options) => {
    const config = {
      params: options,
    };

    return http.get(`${window.baseApiPath}/mailchimp/check-subscription`, config);
  },
  updateSettingsAndCreateExtras: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(
      `${window.baseApiPath}/mailchimp/update-settings-and-create-extras`,
      config,
    );
  },
  reCreateMailchimpMergeFields: (auth) => {
    const { token, currentStaff } = auth;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.put(`${window.baseApiPath}/mailchimp/re-create-merge-fields`, config);
  },
};
