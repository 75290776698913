import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const paymentTypes = createSlice({
  name: 'paymentTypes',
  initialState: initialState,
  reducers: {
    completeLoadPaymentTypes: (state, action) => {
      const ids = action.payload.map(({ id }) => id);
      const byId = action.payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byId,
        ids,
      };
    },
    completeUpdatePaymentTypes: (state, action) => {},
  },
});

export const {
  completeLoadPaymentTypes,
  completeUpdatePaymentTypes,
} = paymentTypes.actions;

export default paymentTypes.reducer;

export const loadPaymentTypes = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/payment-types`, config);

  return dispatch(completeLoadPaymentTypes(response));
};

export const loadPaymentTypesForSettings = () => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(
    `${window.baseApiPath}/payment-types/get-for-settings`,
    config,
  );

  return dispatch(completeLoadPaymentTypes(response));
};

export const updatePaymentTypes = (data) => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/payment-types/multiple-update`, config);

  return dispatch(completeUpdatePaymentTypes(response));
};
