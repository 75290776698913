/* eslint-disable import/no-unresolved */
import { createSlice } from '@reduxjs/toolkit';

import http from '../shared_client_utils/http';

const initialState = {
  staffEmailTemplate: '',
  clientsEmailTemplate: '',
  recoveryEmailTemplate: '',
  checkInEmailTemplate: '',
  staffEmailHtml: '',
  clientsEmailHtml: '',
  recoveryEmailHtml: '',
  checkInEmailHtml: '',
};

const messagingSettings = createSlice({
  name: 'messagingSettings',
  initialState,
  reducers: {
    completeLoadMessagingSettings: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
});

export const {
  completeLoadMessagingSettings,
} = messagingSettings.actions;

export default messagingSettings.reducer;

export const loadMessagingSettings = params => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    params,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/messaging-settings`, config);

  return dispatch(completeLoadMessagingSettings(response));
};

export const saveOrUpdateMessagingSettings = data => async (dispatch, getState) => {
  const { auth: { token }} = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/messaging-settings`, config);

  return dispatch(completeLoadMessagingSettings(response));
};
