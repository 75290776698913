import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';

import initialState from './initialState';
import { loadState, saveState } from './localStorage';
import combinedReducers from './reducers';

const customizedMiddleware = getDefaultMiddleware({
  immutableCheck: false,
});

const rootReducer = (state, action) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    }

    return nextState;
  }

  return combinedReducers(state, action);
};

const localState = initialState(loadState());
export const store = configureStore({
  reducer: rootReducer,
  preloadedState: localState,
  middleware: customizedMiddleware,
});

const makeStore = () => store;

export const wrapper = createWrapper(makeStore);

export { loadState, saveState };
