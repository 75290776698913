/* eslint-disable class-methods-use-this */
import { Fragment } from 'react';
import { Provider } from 'react-redux';
import App from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';

import { store, wrapper, saveState } from '../store';
import theme from '../../../shared_client_utils/theme';
import '../../../shared_client_utils/initializeLocaleForMomentAndDatepicker';

import '../../../shared_styles/styles.css';
// https://err.sh/next.js/css-global
import '@nlevchuk/react-big-calendar/lib/css/react-big-calendar.css';
import '@nlevchuk/react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import 'react-phone-number-input/style.css';
import createEmotionCache from '../createEmotionCache';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

class HiveManagerApp extends App {
  unsubscribe = null;

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => saveState(store.getState()));
    window.addEventListener('beforeunload', this.onUnload);
    this.checkStorage();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  checkStorage() {
    const sessionValue = sessionStorage.getItem('state');
    if (sessionValue) {
      localStorage.setItem('state', sessionValue)
    }
    const localValue = localStorage.getItem('state');
    if (localValue) {
      sessionStorage.setItem('state', localValue);
      let openTabs = localStorage.getItem('openTabs');
      openTabs = Number(openTabs) ? Number(openTabs) + 1 : 1;
      localStorage.setItem('openTabs', openTabs)
    }
  }

  onUnload(e) {
    e.preventDefault();
    let openTabs = localStorage.getItem('openTabs');
    openTabs = Number(openTabs) ? Number(openTabs) - 1 : 0;
    localStorage.setItem('openTabs', openTabs.toString());
    if (!Number(openTabs)) {
      localStorage.removeItem('state');
    }
  }

  render() {
    const { Component, emotionCache = clientSideEmotionCache, pageProps, ...props } = this.props;

    return (
      <>
        <Head>
          <title>
            {Component.pageTitle ? Component.pageTitle : 'Hivemanager App'}
          </title>
          <meta
            name="description"
            content={Component.pageDescription ? Component.pageDescription : 'Hivemanager App'}
          />
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>

        <CacheProvider value={emotionCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
              <Component {...props} {...pageProps} />
            </Provider>
          </ThemeProvider>
        </CacheProvider>
      </>
    );
  }
}

export default wrapper.withRedux(HiveManagerApp);
