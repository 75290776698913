import axios from 'axios';
import http from '../shared_client_utils/http';

export default {
  fetchItemFromAppointment: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-appointment`, config);
  },
  fetchItemFromAppointments: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-appointments`, config);
  },
  fetchItemFromDiscount: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-discount`, config);
  },
  fetchProductItemTemplate: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/get-product-template`, config);
  },
  fetchItemFromProduct: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-product`, config);
  },
  fetchItemFromGiftCard: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-gift-card`, config);
  },
  fetchItemFromClientGiftCard: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-client-gift-card`, config);
  },
  fetchItemFromClientGratuity: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-gratuity`, config);
  },
  fetchItemFromClientCredit: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-client-credit`, config);
  },
  fetchItemsFromClientCredits: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-client-credits`, config);
  },
  fetchItemFromPackage: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-package`, config);
  },
  fetchItemFromClientPackage: (itemData, auth) => {
    const { token } = auth;
    const config = {
      params: { itemData },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/build-from-client-package`, config);
  },
  fetchItemsForSalesReports: (options, auth) => {
    const { token } = auth;
    const config = {
      params: options,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return http.get(`${window.baseApiPath}/invoice-items/get-for-sales-reports`, config);
  },
  fetchEmailHtml: (invoiceId, auth) => {
    const { token } = auth;
    const config = {
      params: { invoiceId },
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.get(`${window.baseApiPath}/invoice-emails/get-email-html`, config);
  },
  fetchPdf: (invoiceId) => {
    return axios.get(`${window.baseApiPath}/invoices/${invoiceId}/pdf`, {params: { isHtml: 'ok' }});

    // return axios.get(`${window.baseApiPath}/invoices/${invoiceId}/pdf`, {
    //   headers: {
    //     Accept: 'application/pdf',
    //   },
    //   responseType: 'blob',
    // });
  },
  exportSalesReportInCSV: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/invoice-items/export-sales-report-in-csv`, {
      params: options,
      headers: {
        Accept: 'text/csv',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
  exportSalesReportInExcel: (options, auth) => {
    const { token } = auth;

    return axios.get(`${window.baseApiPath}/invoice-items/export-sales-report-in-excel`, {
      params: options,
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        Authorization: `Bearer ${token}`
      },
      responseType: 'blob',
    });
  },
  requestOnlinePayment: (id, data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/invoices/${id}/request-online-payment`, config);
  },
  fetchInvoiceForOnlinePayment: (id) => {
    return http.get(`${window.baseApiPath}/invoices/${id}/get-for-online-payment`, {});
  },
  createInvoiceItemFromClientGiftCard: (data) => {
    return http.post(
      `${window.baseApiPath}/invoice-items/create-from-client-gift-card`,
      { data },
    );
  },
  completeByGiftCard: (id, data) => {
    const config = { data };
    return http.put(`${window.baseApiPath}/invoices/${id}/complete-by-gift-card`, config);
  },
  fetchButchPrintHtml: (data, auth) => {
    const { token } = auth;
    const config = {
      data,
      headers: {
        Authorization: `Bearer ${token}`
      },
    };

    return http.post(`${window.baseApiPath}/invoices/get-butch-print-html`, config);
  }
};
