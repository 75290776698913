import { createSlice } from '@reduxjs/toolkit';

import http from '../../../shared_client_utils/http';

const initialState = { byClientId: {} };

const clientFiles = createSlice({
  name: 'clientFiles',
  initialState: initialState,
  reducers: {
    completeLoadClientFiles: (state, action) => {
      const { clientFiles, clientId } = action.payload;

      const ids = clientFiles.map(({ id }) => id);
      const byId = clientFiles.reduce((prevValue, currValue) => ({
        ...prevValue, [currValue.id]: currValue,
      }), {});

      return {
        ...state,
        byClientId: {
          ...state.byClientId,
          [clientId]: {
            byId,
            ids,
          },
        },
      };
    },
    completeCreateClientFile: (state, action) => {
      const { clientFile, clientId } = action.payload;

      const clientFiles = state.byClientId[clientId];
      const byId = {
        ...clientFiles.byId,
        [clientFile.id]: clientFile,
      };
      const ids = [...clientFiles.ids, clientFile.id];

      return {
        ...state,
        byClientId: {
          ...state.byClientId,
          [clientId]: {
            byId,
            ids,
          },
        },
      };
    },
    completeUpdateClientFile: (state, action) => {
      const { clientFile, clientId } = action.payload;

      const clientFiles = state.byClientId[clientId];
      const byId = {
        ...clientFiles.byId,
        [clientFile.id]: clientFile,
      };

      return {
        ...state,
        byClientId: {
          ...state.byClientId,
          [clientId]: {
            byId,
            ids: clientFiles.ids,
          },
        },
      };
    },
    completeDeleteClientFile: (state, action) => {
      const { id, clientId } = action.payload;

      const clientFiles = state.byClientId[clientId];
      const { [id]: deletedClientFile, ...byId } = clientFiles.byId;

      return {
        ...state,
        byClientId: {
          ...state.byClientId,
          [clientId]: {
            byId,
            ids: clientFiles.ids.filter((fileId) => fileId !== id),
          },
        },
      };
    },
  },
});

export const {
  completeLoadClientFiles,
  completeCreateClientFile,
  completeUpdateClientFile,
  completeDeleteClientFile,
} = clientFiles.actions;

export default clientFiles.reducer;

export const loadClientFiles = (clientId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: { clientId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const clientFiles = await http.get(`${window.baseApiPath}/client-files`, config);

  return dispatch(completeLoadClientFiles({ clientId, clientFiles }));
};

export const createClientFile = (data, clientId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const clientFile = await http.post(`${window.baseApiPath}/client-files`, config);

  return dispatch(completeCreateClientFile({ clientId, clientFile }));
};

export const updateClientFile = (id, data, clientId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const clientFile = await http.put(`${window.baseApiPath}/client-files/${id}`, config);

  return dispatch(completeUpdateClientFile({ clientId, clientFile }));
};

export const deleteClientFile = (id, clientId) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: { clientId },
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/client-files/${id}`, config);

  return dispatch(completeDeleteClientFile({ clientId, id }));
};
