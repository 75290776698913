import { createSlice } from '@reduxjs/toolkit';

import http from "../../../shared_client_utils/http";

const initialState = { byId: {}, ids: [] };

const giftCards = createSlice({
  name: 'giftCards',
  initialState: initialState,
  reducers: {
    completeLoadGiftCards: (state, { payload }) => {
      const ids = payload.map(({ id }) => id);
      const byId = payload.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});
      return { ...state, byId, ids };
    },
    completeLoadSortedGiftCards: (state, { payload }) => {
      const ids = payload.giftCards.map(({ id }) => id);
      const byId = payload.giftCards.reduce((result, currValue) => ({
        ...result, [currValue.id]: currValue,
      }), {});

      return { ...state, byId, ids };
    },
    completeCreateGiftCard: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
        ids: [
          ...state.ids,
          payload.id,
        ],
      };
    },
    completeUpdateGiftCard: (state, { payload }) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: payload,
        },
      };
    },
    completeDeleteGiftCard: (state, { payload }) => {
      const { [payload]: deletedGiftCard, ...byId } = state.byId;
      return {
        ...state,
        byId,
        ids: state.ids.filter(id => id !== payload),
      };
    },
  },
});

export const {
  completeLoadGiftCards,
  completeLoadSortedGiftCards,
  completeCreateGiftCard,
  completeUpdateGiftCard,
  completeDeleteGiftCard,
} = giftCards.actions;

export default giftCards.reducer;

export const loadGiftCards = () => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/gift-cards`, config);

  return dispatch(completeLoadGiftCards(response));
};

export const loadSortedGiftCards = options => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    params: options,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.get(`${window.baseApiPath}/gift-cards/get-sorted`, config);

  return dispatch(completeLoadSortedGiftCards(response));
};

export const createGiftCard = data => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.post(`${window.baseApiPath}/gift-cards`, config);

  return dispatch(completeCreateGiftCard(response));
};

export const updateGiftCard = (id, data) => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    data,
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const response = await http.put(`${window.baseApiPath}/gift-cards/${id}`, config);

  return dispatch(completeUpdateGiftCard(response));
};

export const deleteGiftCard = id => async (dispatch, getState) => {
  const { auth: { token } } = getState();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  await http.delete(`${window.baseApiPath}/gift-cards/${id}`, config);

  return dispatch(completeDeleteGiftCard(id));
};
